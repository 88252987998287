<template>
    <div class="Fs">
        <Nav
            nav-title="关于复数"
            :pic-url="picUrl"
        >
            <el-collapse
                v-model="activeName"
                accordion
            >
                <el-collapse-item
                    title="公司介绍"
                    name="1"
                >
                    <ul class="left-nav-list">
                        <li
                            v-for="item in companyIntroductionList"
                            :key="item.id"
                            @click="changeTab(item.id,item.tabIndex)"
                            :class="{selectedLi:selectedIndex=== item.id}"
                        >
                            <router-link :to="item.url">
                                <i class="iconfont icon-youjiantou1" />
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </el-collapse-item>
                <ul
                    class="fs-ul"
                    name="2"
                >
                    <li
                        @click="changeTab('3','1')"
                        :class="{selectedLi:selectedIndex === '3'}"
                    >
                        <router-link
                            to="/fs/contact"
                        >
                            <span>联系我们</span>
                            <i class="iconfont icon-youjiantou" />
                        </router-link>
                    </li>
                </ul>
            </el-collapse>
        </Nav>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'Fs',
  components: {
      Nav
  },
  data () {
        return {
          picUrl: require('../assets/images/bgImg/guanyufushu.png'),
          activeName: '2',
          companyIntroductionList: [
            {
              id: '1',
              url: '/fs/about',
              name: '公司简介',
              tabIndex: '4'
            },
            {
              id: '2',
              url: '/fs/founder',
              name: '创始人',
              tabIndex: '4-2'
            }
          ],
          selectedIndex: '-1'
        }
  },
  methods: {
      changeTab (index, tabIndex) {
        this.$store.commit('changeTopNav', tabIndex)
        this.selectedIndex = index
      }
  },
  computed: {
  },
  watch: {
    '$store.state.selectedTabIndex': function (newVal, oldVal) {
        if (newVal === '1') {
          this.selectedIndex = '3'
          this.activeName = '2'
        } else if (newVal === '4') {
          this.selectedIndex = '1'
          this.activeName = '1'
        }
    }
  },
  created () {
    if (this.$store.state.selectedTabIndex === '1') {
      this.selectedIndex = '3'
      this.activeName = '2'
    } else if (this.$store.state.selectedTabIndex === '4') {
      this.selectedIndex = '1'
      this.activeName = '1'
    } else if (this.$store.state.selectedTabIndex === '4-2') {
      this.selectedIndex = '2'
      this.activeName = '1'
    }
  }
}
</script>
<style lang="less" scoped>
.Fs{
  .left-nav-list{
      list-style: none;
      padding-left: 0;
      li{
        width: 100%;
        padding: 0.625rem;
        border-bottom: 1px solid #f2f2f2;
        a{
          width: 100%;
          height: 100%;
          display: block;
          color: rgba(0,0,0,.65);
          i{
            color: rgba(0,0,0,.65);
          }
          span{
          font-size:1rem;

          }
        }
      }

  }
  .fs-ul{
    list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li{
        height: 48px;
        line-height: 48px;
        border-bottom:1px solid #EBEEF5;
        a{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: inherit;
          font-size:1rem;
          span{
            color: rgba(0, 0, 0, 0.65);
          }
          i{
            margin-right: 8px;
            font-size: 20px;
              color: rgba(0, 0, 0, 0.45);
          }
        }
      }
  }
  .selectedLi{
      background: rgb(245, 247, 250);
      border-radius: 5px;
      span{
        font-weight: 600;
      }
  }
}
</style>
